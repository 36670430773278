const gxjy = () => import('../../views/gxjy/index.vue');
const index = () => import('../../views/home/index.vue');
const zhaobiao = () => import('../../views/gxjy/zhaobiao.vue');
const zhaobiaoxq = () => import('../../views/gxjy/zhaobiaoxq.vue');
const allzhaobiao = () => import('../../views/gxjy/allzhaobiao.vue');
const allzhaobiaoxq = () => import('../../views/gxjy/allzhaobiaoxq.vue');
const caigouxq = () => import('../../views/gxjy/caigouxq.vue');
const caigou = () => import('../../views/gxjy/caigou.vue');
const kucun = () => import('../../views/gxjy/kucun.vue');
const zixun = () => import('../../views/gxjy/zixun.vue');
const kucunxq = () => import('../../views/gxjy/kucunxq.vue');
const allhuoke = () => import('../../views/gxjy/allhuoke.vue');
export default [ {
    path: '/index',
    name: 'index',
    component: index,
    children:[{
        path: '/gxjy',
        name: 'gxjy',
        component: gxjy,
    },{
        path: '/zhaobiao',
        name: 'zhaobiao',
        component: zhaobiao,
    },{
        path: '/zhaobiaoxq',
        name: 'zhaobiaoxq',
        component: zhaobiaoxq,
    },{
        path: '/allzhaobiao',
        name: 'allzhaobiao',
        component: allzhaobiao,
    },{
        path: '/allzhaobiaoxq',
        name: 'allzhaobiaoxq',
        component: allzhaobiaoxq,
    },
    {
        path: '/allhuoke',
        name: 'allhuoke',
        component: allhuoke,
    },{
        path: '/caigouxq',
        name: 'caigouxq',
        component: caigouxq,
    },{
        path: '/caigou',
        name: 'caigou',
        component: caigou,
    },{
        path: '/zixun',
        name: 'zixun',
        component: zixun,
    },{
        path: '/kucun',
        name: 'kucun',
        component: kucun,
    },{
        path: '/kucunxq',
        name: 'kucunxq',
        component: kucunxq,
    }]
}
];
