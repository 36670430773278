import {$get, $post} from '../http';
import {baseUrl} from "@/request/global";

var api = baseUrl
export const policyindex = (params) => {
    return $get(api+'/idsp-pc/policy/index',params)
}
export const policyunscrambleindex = (params) => {
    return $get(api+'/idsp-pc/policy-unscramble/index',params)
}
export const policyprojectindex = (params) => {
    return $get(api+'/idsp-pc/policy-project/index',params)
}
export const policypage = (params) => {
    return $get(api+'/idsp-pc/policy/page',params)
}
export const policyunscramblepage = (params) => {
    return $get(api+'/idsp-pc/policy-unscramble/page',params)
}
export const policyprojectpage = (params) => {
    return $get(api+'/idsp-pc/policy-project/page',params)
}
export const policydetail = (params) => {
    return $get(api+'/idsp-pc/policy/detail',params)
}
export const policyunscrambledetail = (params) => {
    return $get(api+'/idsp-pc/policy-unscramble/detail',params)
}
export const policyprojectdetail = (params) => {
    return $get(api+'/idsp-pc/policy-project/detail',params)
}
export const policymergeDetail = (params) => {
    return $get(api+'/idsp-pc/policy/mergeDetail',params)
}
export const noticeselect = (params) => {
    return $get(api+'/idsp-pc/notice/select',params)
}
export const noticedetail = (params) => {
    return $get(api+'/idsp-pc/notice/detail',params)
}
export const enterLogin = (params) => {
    return $post(api+'/idsp-pc/login/enterprise-login',params)
}
export const saveReq = (params) => {
    return $post(api+'/idsp-pc/policy-project/save-req',params)
}
//字典
export const dictList = (params) => {
    return $get(api+'/blade-system/dict/list',params)
}
//精准获客
export const entList = (params) => {
    return $post(api+'/',params)
}


//企业查询
export const entOnlineList = (params) => {
    return $post(api+'/idsp-pc/enterprise/search',params)
    // return $post('http://27.128.160.192:8100/idsp-pc/enterprise/search',params)

}
//////////////////////
//企业所在地树
export const addressTree = (params) => {
    return $get(api+'/idsp-pc/enterprise/address-tree',params)
    // return $get('http://27.128.160.192:8100/idsp-pc/enterprise/address-tree',params)

}

//行业树
export const industryTree = (params) => {
    return $get(api+'/idsp-pc/enterprise/industry-tree',params)
    // return $get('http://27.128.160.192:8100/idsp-pc/enterprise/industry-tree',params)
}



