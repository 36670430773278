import {$get, $post} from '../http';
import {baseUrl} from "@/request/global";

var api = baseUrl
export const enterprisedigitizationindex = (params) => {
    return $get(api+'/idsp-pc/intelligent-manufacturing/enterprise-digitization-index',params)
}
export const privatemallindex = (params) => {
    return $get(api+'/idsp-pc/intelligent-manufacturing/private-mall-index',params)
}
export const warehousinglogisticsindex = (params) => {
    return $get(api+'/idsp-pc/intelligent-manufacturing/warehousing-logistics-index',params)
}
export const productionmanagementindex = (params) => {
    return $get(api+'/idsp-pc/intelligent-manufacturing/production-management-index',params)
}
export const equipmentmanagementindex = (params) => {
    return $get(api+'/idsp-pc/intelligent-manufacturing/equipment-management-index',params)
}
export const digitaltwinindex = (params) => {
    return $get(api+'/idsp-pc/intelligent-manufacturing/digital-twin-index',params)
}
export const lowcodeindex = (params) => {
    return $get(api+'/idsp-pc/intelligent-manufacturing/low-code-index',params)
}
export const signapplyindex = (params) => {
    return $get(api+'/idsp-pc/sign-apply/index',params)
}
export const networksecurityindex = (params) => {
    return $get(api+'/idsp-pc/intelligent-manufacturing/network-security-index',params)
}
export const mapindex = (params) => {
    return $get(api+'/idsp-pc/industrial-chain-map/index',params)
}
export const requestConsultateMessage= (params) => {
    return $post(api+'/idsp-pc/intelligent-manufacturing/consult',params)
}
