import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import animated from 'animate.css'
import './assets/icons/icon.css'
import './assets/reset.css'
import './assets/common.css'
import './assets/override-element-ui.css'
// 引入echarts
import * as echarts from 'echarts'
import "echarts-gl";
Vue.prototype.$echarts = echarts
import VueCountUp from 'vue-countupjs'
Vue.use(VueCountUp)
Vue.component('VueCountUp', VueCountUp)
// 全局调用js-cookie
import Cookies from 'js-cookie'
Vue.prototype.$cookie = Cookies
import store from './store';
import api from './request/api'

// 引用公共js
import common from './static/js/global'
Vue.prototype.common = common

// Vue.use(Cookies)
Vue.prototype.$api = api

import VueAwesomeSwiper from 'vue-awesome-swiper'
// import 'swiper/dist/css/swiper.css'
Vue.use(VueAwesomeSwiper)
Vue.use(animated)
Vue.config.productionTip = false
Vue.use(ElementUI);  // 注册使用Element
Vue.use(window.AVUE)

import '@/assets/font/font.css'

// 将自动注册所有组件为全局组件
import dataV from '@jiaminghi/data-view'
Vue.use(dataV)
import VueSeamlessScroll from 'vue-seamless-scroll'
Vue.use(VueSeamlessScroll)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
