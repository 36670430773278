const common = {
  state: {
    zchjform: '',
    zcjdform:'',
    xmsbform:'',
    zhaobiaoform:'',
    caigouform:'',
    xiaoshouform:''
  },
  mutations: {
    setzchjform: (state, form) => {
      state.zchjform = form
    },
    setzcjdform: (state, form) => {
      state.zcjdform = form
    },
    setxmsbform: (state, form) => {
      state.xmsbform = form
    },
    setzhaobiaoform: (state, form) => {
      state.zhaobiaoform = form
    },
    setcaigouform: (state, form) => {
      state.caigouform = form
    },
    setxiaoshouform: (state, form) => {
      state.xiaoshouform = form
    },
  }
}
export default common
